module.exports = {
  presence: 'Presence',
  presences: {
    others: 'Others',
    onTime: 'On time',
    patientCancelledPayable: 'Patient cancelled - payable',
    patientCancelledNotPayable: 'Patient cancelled - not payable',
    meetingCancelledByMe: 'Meeting cancelled by me',
    meetingDidNotHappen: 'Meeting did not happen',
    didNotHappen: 'Did not happen',
    holiday: 'Holiday',
    outsideWorkingHours: 'Outside of working hours',
  },
  groupPresences: {
    meetingTookPlace: 'Meeting took place',
    meetingCancelled: 'Meeting cancelled',
    meetingCancelledByMe: 'Meeting cancelled by me',
    onePartyCancelled: 'One party cancelled',
    didNotHappen: 'Did not happen',
    holiday: 'Holiday',
    outsideWorkingHours: 'Outside of working hours',
  },
  service: 'Service:',
  meeting: 'Meeting:',
  services: 'Services',
  addNewService: 'Add new service',
  selectServices: 'Select services',
  payment: 'Payment',
  paymentDate: 'Payment date',
  attachmentDeleted: 'Event attachment has been deleted',
  payments: {
    paid: 'Paid',
    paymentNotRequired: 'Payment not required',
    awaitingPayment: 'Awaiting payment',
    other: 'Other',
    unpaid: 'Unpaid',
    unknown: 'Unknown',
    refunded: 'Refunded',
    przelewy24: 'Przelewy24',
  },
  paymentMethod: 'Payment method',
  paymentMethods: {
    cash: 'Cash',
    card: 'Card',
    creditCard: 'Card',
    bankTransfer: 'Bank transfer',
    transfer: '@:sessions.paymentMethods.bankTransfer',
    other: 'Other',
    przelewy24: 'Przelewy24',
  },
  meetingNote: 'Meeting note',
  attachmentsInTheFuture: 'Expect those attachments to be added here soon!',
  placeholders: {
    presence: 'Choose presence',
    service: 'Choose service',
    payment: 'Choose payment',
    prepaidMeetings: 'Prepaid meetings',
    paymentMethod: 'Choose payment method',
  },
  tooltips: {
    prepaidMeetings: 'Prepaid meetings coming up soon!',
    saveService: 'At least one service has to be selected',
  },
  cards: {
    contactDetails: 'Contact details',
    upcomingMeetings: 'Upcoming meetings',
    history: 'History',
    attachments: 'Attachments',
    invoices: 'Invoices',
    gdpr: 'GDPR',
    notifications: 'Notifications',
  },
  tabs: {
    meetingDetails: 'Meeting details',
  },
  notesOnlyMainTherapist:
    'Session notes visible only to the attending therapist',
  noAccess: 'Sorry, you have no access rights to see those details',
  pdfFields: {
    therapistName: 'Therapist name: ',
    practiceName: 'Practice name: ',
    patient: 'Patient: ',
    meetingType: 'Meeting type: ',
    meetingDateAndTime: 'Meeting date and time: ',
    meetingNotes: 'Meeting notes: ',
    meetingSecretNotes: 'Meeting secret notes: ',
    meetingNoteTitle: 'Meeting notes',
  },
  print: {
    note: 'Print patient note',
    all: 'Print all',
  },
}
