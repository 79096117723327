module.exports = {
  fileSizeMore5MB: 'File size is more than 5MB',
  selectAndClickToTrigger: 'Select element(s) and click to trigger action',
  addedOn: 'Added on:',
  yes: 'Yes',
  no: 'No',
  new: 'New',
  view: 'View',
  edit: 'Edit',
  done: 'Done',
  cancel: 'Cancel',
  selected: 'Selected',
  delete: 'Delete',
  fullName: 'Full name',
  details: 'Details',
  roles: 'Roles',
  color: 'Color',
  download: 'Download',
  copiedSuccessfully: 'Copied Successfully',
  // nazwa
  name: 'Name',
  slug: 'Short name',
  // imię
  firstName: '@:common.name',
  email: 'Email',
  surname: 'Surname',
  never: 'Never',
  always: 'Always',
  birthday: 'Birthday',
  idNumber: 'ID number',
  print: 'Print',
  phoneNumber: 'Phone number',
  phoneNumber2: 'Phone number 2',
  sendSmsTo: 'Send SMS to',
  phone: 'Phone',
  addNew: 'Add new',
  added: 'Added',
  ended: 'Ended',
  filters: 'Filters',
  location: 'Location',
  assignedTo: 'Assigned to',
  search: 'Search',
  address: 'Address',
  notAvailableYet: 'Sorry, not available yet!',
  selectPracticeBranch: 'Select practice branch',
  saveChanges: 'Save changes',
  save: 'Save',
  sendInvitation: 'Send invitation',
  nextStep: 'Next step',
  comments: 'Comments',
  active: 'Active',
  inactive: 'Inactive',
  profile: 'Profile',
  logOut: 'Log out',
  chooseLanguage: 'Choose your language',
  noResults: 'No results',
  noOptions: 'No options available',
  photo: 'Photo',
  remove: 'Remove',
  change: 'Change',
  from: 'From',
  until: 'Until',
  copyToClipboard: 'Copy to clipboard',
  copiedToClipboard: 'Copied to clipboard',
  shortMonths: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sept: 'Sept',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
  },
  tooltip: {
    maxCoupleWarning:
      'A couple can have maximum 2 patients added! If you need more please consider creating a group!',
    findAFreeSlot: 'Find a free slot',
  },
  emptyTableAddNewRow: 'If you would like to add one, click the button below',
  add: 'Add',
  confirmDialogHeader: 'Confirm your action',
  sthWentWrong: 'Something went wrong',
  tryAgain: 'Try again',
  soonToBeAdded: 'Will be added soon!',
  more: 'more',
  heading: 'Heading',
  subheading: 'Subheading',
  normalText: 'Normal',
  addressInformation: 'Address information',
  contactDetails: 'Contact details',
  invoiceData: 'Invoice data',
  showDetails: 'Add patient address',
  hideDetails: 'Hide details',
  gdpr: 'GDPR',
  uploadOrDrag: 'Upload or drag and drop a file',
  date: {
    range: {
      select: 'Select range',
      currentMonth: 'Current month',
      previousMonth: 'Previous month ',
      last3Months: 'Last 3 months',
      nextMonth: 'Next month',
    },
  },
  therapist: 'Therapist:',
  office: 'Office:',
  patient: 'Patient:',
  dateSession: 'Date of the session:',
  sessionNotes: 'Session notes:',
  total: 'Total',
  expand: 'Expand',
  collapse: 'Collapse',
  copyNumber: 'Copy Number',
  copyEmail: 'Copy Email',
  sendSms: 'Send Sms',
  sendEmail: 'Send Email',
  close: 'Close',
  refresh: 'Refresh',
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms and Conditions',
  submit: 'Submit',
  acceptAll:
    'I hereby acknowledge that I have read and understood the Terms and Conditions and the Privacy Policy.',
  generatedOn: 'Generated on: ',
  at: 'at',
  atTimes: 'at',
  byDobryGabinet: 'by DobryGabinet.com',
  page: 'Page',
  pageReportTemplate: {
    singlePage: {
      singleRow:
        'Showing {rows} entity on page {currentPage} out of {totalPages} page',
      manyRows:
        'Showing {rows} entities on page {currentPage} out of {totalPages} page',
      manyRowsPolish:
        'Showing {rows} entities on page {currentPage} out of {totalPages} page',
    },
    singleRow:
      'Showing {rows} entity on page {currentPage} out of {totalPages} pages',
    manyRows:
      'Showing {rows} entities on page {currentPage} out of {totalPages} pages',
    manyRowsPolish:
      'Showing {rows} entities on page {currentPage} out of {totalPages} pages',
  },
  markAsPaid: 'Mark as paid',
  clickToMarkAsPaid: 'Click to change the status of selected meetings',
  clear: 'Clear',
  apply: 'Apply',
  attachments: {
    title: 'Attachments',
    deleteTitle: 'Deleting file:',
    deleteCaption: 'Are you sure you want to delete this file?',
  },
  show: 'Show',
  placeholderAll: 'All options',
  next: 'Next',
  back: 'Back',
  open: 'Open',
  branch: 'Branch',
  consent: 'I consent',
  notSpecified: 'Not specified',
  helpers: {
    needHelp: 'Need help?',
    clickHere: 'Click here',
  },
}
